import $ from 'jquery';

$(function() {
  let tabs = $('.p-faq-tab__item');
  $('.p-faq-tab__item').on('click', function() {
    $('.p-faq-tab__item').removeClass('is-active');
    $(this).addClass('is-active');
    const index = tabs.index(this);
    $('.p-faq-content').removeClass('is-show').eq(index).addClass('is-show');
  })
})