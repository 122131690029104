import Swiper, { Autoplay, Navigation, Pagination, EffectFade, Thumbs } from 'swiper';

Swiper.use([Autoplay,Navigation,Pagination,EffectFade,Thumbs]);

const singleThumbnail = new Swiper('.p-global-rooms__slide-thumbnail--single', {
  slidesPerView: 5,
  spaceBetween: 5,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

const sliderSingle = new Swiper('.p-global-rooms__slide-wrap--single', {
  loop: true,
  autoplay: {
    delay: 3000,
  },
  navigation: {
    nextEl: '.swiper-button-prev',
    prevEl: '.swiper-button-next',
  },
  thumbs: {
    swiper: singleThumbnail
  }
});
const semiThumbnail = new Swiper('.p-global-rooms__slide-thumbnail--semi-double', {
  slidesPerView: 5,
  spaceBetween: 5,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

const sliderSemi = new Swiper('.p-global-rooms__slide-wrap--semi-double', {
  loop: true,
  autoplay: {
    delay: 3000,
  },
  navigation: {
    nextEl: '.swiper-button-prev',
    prevEl: '.swiper-button-next',
  },
  thumbs: {
    swiper: semiThumbnail
  }
});

const twinThumbnail = new Swiper('.p-global-rooms__slide-thumbnail--twin', {
  slidesPerView: 5,
  spaceBetween: 5,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

const sliderTwin = new Swiper('.p-global-rooms__slide-wrap--twin', {
  loop: true,
  autoplay: {
    delay: 3000,
  },
  navigation: {
    nextEl: '.swiper-button-prev',
    prevEl: '.swiper-button-next',
  },
  thumbs: {
    swiper: twinThumbnail
  }
});

const cornerThumbnail = new Swiper('.p-global-rooms__slide-thumbnail--corner-twin', {
  slidesPerView: 5,
  spaceBetween: 5,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

const sliderCorner = new Swiper('.p-global-rooms__slide-wrap--corner-twin', {
  loop: true,
  autoplay: {
    delay: 3000,
  },
  navigation: {
    nextEl: '.swiper-button-prev',
    prevEl: '.swiper-button-next',
  },
  thumbs: {
    swiper: cornerThumbnail
  }
});

const barrireThumbnail = new Swiper('.p-global-rooms__slide-thumbnail--barrier-free', {
  slidesPerView: 5,
  spaceBetween: 5,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

const sliderBarrier = new Swiper('.p-global-rooms__slide-wrap--barrier-free', {
  loop: true,
  autoplay: {
    delay: 3000,
  },
  navigation: {
    nextEl: '.swiper-button-prev',
    prevEl: '.swiper-button-next',
  },
  thumbs: {
    swiper: barrireThumbnail
  }
});

const annexThumbnail = new Swiper('.p-global-rooms__slide-thumbnail--annex', {
  slidesPerView: 5,
  spaceBetween: 5,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

const sliderAnnex = new Swiper('.p-global-rooms__slide-wrap--annex', {
  loop: true,
  autoplay: {
    delay: 3000,
  },
  navigation: {
    nextEl: '.swiper-button-prev',
    prevEl: '.swiper-button-next',
  },
  thumbs: {
    swiper: annexThumbnail
  }
});
