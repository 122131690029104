function initMap() {
  const map = new google.maps.Map(document.getElementById('map'), {
    zoom: 13,
    center: { lat: 26.3609146, lng: 127.8105487 },
    mapTypeControl: false,
  });

  const tourStops = [
    [
      { lat: 26.3329509, lng: 127.7832326 },
      `<span class="p-sightseeing-check-map__spot-name">沖縄アリーナ</span>〒904-0034 沖縄県沖縄市山内1丁目16-1<br>TEL:050-5444-1478<br>営業時間：月～金曜日/16:00～20:00<br>土・日・祝祭日/11:00～20:00<br>※火曜は定休日`
    ],
    [
      { lat: 26.3334723, lng: 127.7851502 },
      `<span class="p-sightseeing-check-map__spot-name">沖縄市体育館</span>〒904-0032 沖縄県沖縄市諸見里2丁目1-1<br>TEL:098-932-0777<br>営業時間：9:00～12:00`
    ],
    [
      { lat: 26.3350356, lng: 127.7970992 },
      `<span class="p-sightseeing-check-map__spot-name">コザミュージックタウン</span>〒904-0031 沖縄県沖縄市上地1丁目1-1-3F<br>TEL:098-932-1949<br>営業時間：24時間・年中無休`
    ],
    [
      { lat: 26.3272399, lng: 127.8016805 },
      `<span class="p-sightseeing-check-map__spot-name">沖縄こどもの国</span>〒904-0032 沖縄県沖縄市諸見里2丁目1-1<br>TEL:098-932-0777<br>営業時間：4月～9月 9:30～18:00　※入場は17:00まで<br>10月～3月 9:30～17:30　※入場は16:30まで`
    ],
    [
      { lat: 26.334201, lng: 127.7856097 },
      `<span class="p-sightseeing-check-map__spot-name">沖縄市コザ運動公園</span>〒904-0032 沖縄県沖縄市諸見里2丁目1-1<br>TEL:098-932-0777<br>営業時間：9:00～12:00`
    ],
    [
      { lat: 26.331307, lng: 127.796984 },
      `<span class="p-sightseeing-check-map__spot-name">中の町ナイトスポット</span>〒904-0031 沖縄県沖縄市上地<br><a href="http://kozaweb.jp/walk/naka.html" target="_blank">詳しい詳細はこちらから</a>`
    ],
    [
      { lat: 26.3191636, lng: 127.791452 },
      `<span class="p-sightseeing-check-map__spot-name">プラザハウス</span>〒904-0023 沖縄県沖縄市久保田3丁目1番12号<br>TEL:098-932-4480<br>営業時間：10:00～20:00※不定休`
    ],
    [
      { lat: 26.3755422, lng: 127.8043822 },
      `<span class="p-sightseeing-check-map__spot-name">東南植物楽園</span>〒904-2143 沖縄県沖縄市知花2146<br>TEL:098-939-2555<br>営業時間：お問い合わせください`
    ],
    [
      { lat: 26.362911, lng: 127.8086385 },
      `<span class="p-sightseeing-check-map__spot-name">知花グスク</span>〒904-2143 沖縄県沖縄市知花5丁目12<br>営業時間：24時間`
    ],
    [
      { lat: 26.3843852, lng: 127.8031293 },
      `<span class="p-sightseeing-check-map__spot-name">モータースポーツマルチフィード</span>〒904-2145 沖縄県沖縄市倉敷152-8<br>TEL:098-923-0187<br>営業時間：9:00～17:00<br>※施設開場時間08:00～／受付開始時間08:30～`
    ],
  ];

  const maplist = [];
  const infoWindow = new google.maps.InfoWindow();

  tourStops.forEach(([position, title], spot) => {
    const marker = new google.maps.Marker({
      position,
      map,
      title: `${title}`,
      optimized: false,
    });

    marker.addListener('click', () => {
      infoWindow.close();
      infoWindow.setContent(marker.getTitle());
      infoWindow.open(marker.getMap(), marker);
    });

    maplist.push(marker);

    const mapTargetItem = [...document.querySelectorAll('.js-map-target')];

    function classRemove() {
      for(let i = 0; i < mapTargetItem.length; i++){
        mapTargetItem[i].classList.remove('is-active');
      }
    }

    mapTargetItem.forEach((item, index) => {
      item.addEventListener('click', () => {
        classRemove();
        google.maps.event.trigger(maplist[index], 'click');
        item.classList.add('is-active');
      })
    })
  });
}

window.initMap = initMap;

