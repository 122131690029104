import Swiper, { Autoplay, Navigation, Pagination, EffectFade, Thumbs } from 'swiper';

Swiper.use([Autoplay,Navigation,Pagination,EffectFade,Thumbs]);

// import Swiper from 'swiper/bundle'; // 全ての機能が入っている

// 客室詳細 MVスライダー
var sliderThumbnail = new Swiper('.p-room-lower-mv__slide-thumbnail', {
  slidesPerView: 5,
  spaceBetween: 5,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

//スライダー
var slider = new Swiper('.p-room-lower-mv__slide-wrap', {
  loop: true,
  autoplay: {
    delay: 3000,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  thumbs: {
    swiper: sliderThumbnail
  }
});




// // TOPページ newsスライダー
// const newsSlideLength = document.querySelectorAll('.p-top-news__slide .swiper-slide').length;

// if (newsSlideLength <= 4) {
//   new Swiper('.p-top-news__slide-wrap', {
//     watchOverflow: true,
//     loop: true,
//     slidesPerView: 1.5,
//     spaceBetween: 20,
//     pagination: {
//       el: '.swiper-pagination',
//       clickable: true,
//     },
//     navigation: {
//       nextEl: '.swiper-button-next',
//       prevEl: '.swiper-button-prev',
//     },
//     breakpoints: {
//       1024: {

//         loop: true,
//         slidesPerView: 3,
//         spaceBetween: 50,
//       },
//       767: {

//         loop: true,
//         slidesPerView: 2,
//         spaceBetween: 30,
//       }
//     }
//   });

// } else {
//   new Swiper('.p-top-news__slide-wrap', {
//     watchOverflow: true,
//     loop: true,
//     slidesPerView: 1,
//     spaceBetween: 20,
//     slidesPerView: 'auto',
//     pagination: {
//       el: '.swiper-pagination',
//       clickable: true,
//     },
//     breakpoints: {
//       1024: {
//         slidesPerView: 3,
//         spaceBetween: 30,
//         navigation: {
//           nextEl: '.swiper-button-next',
//           prevEl: '.swiper-button-prev',
//         },
//       },
//       767: {
//         slidesPerView: 3,
//         spaceBetween: 30,
//         navigation: {
//           nextEl: '.swiper-button-next',
//           prevEl: '.swiper-button-prev',
//         },
//       }
//     }
//   });
// }

// // TOPページ facilityスライダー
// new Swiper('.p-top-facilities__slide-wrap', {
//   loop: true,
//   slidesPerView: 2.2,
//   spaceBetween: 10,
//   pagination: {
//     el: '.p-top-facilities__swiper-pagination',
//     clickable: true,
//     type:"bullets",
//   },
//   navigation: {
//     nextEl: '.swiper-button-next',
//     prevEl: '.swiper-button-prev',
//   },
//   breakpoints: {
//     767: {
//       slidesPerView: 3,
//       spaceBetween: 20,
//     }
//   }
// });