import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
const anchors = document.querySelectorAll('a[href^="#"]');
const header = document.querySelector('header').offsetHeight; 
const urlHash = location.hash; 

for ( let i = 0; i < anchors.length; i++ ) {
  anchors[i].addEventListener('click', (e) => {
    e.preventDefault();
    const href= anchors[i].getAttribute("href");
    if (href !== '#top') {
      const target = document.getElementById(href.replace('#', ''));
      const position = window.pageYOffset + target.getBoundingClientRect().top - header;
      window.scroll({
        top: position,
        behavior: 'smooth'
      });
    } else {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  });
}

//別ページから遷移して来た場合
window.addEventListener('DOMContentLoaded', function() {
  if (urlHash) {
    setTimeout(function (){
      window.scrollTo({top: 0},0);
    })
    setTimeout(function () {
      const urlTarget = document.getElementById(urlHash.replace('#', ''));
      const urlPosition = window.pageYOffset + urlTarget.getBoundingClientRect().top - header;
      console.log(urlTarget)
      window.scroll({
        top: urlPosition,
        behavior: 'smooth'
      });
    }, 200);
  }
}); 